import Header from "@layout/header/header";

import TailwindToggle from "@components/search/TailwindToggle";
import CustomRangePicker from "./CustomRangePicker";
import { SearchIcon } from "@components/search/ComponentIcon";
import { IoClose } from "react-icons/io5";
import { BsArrowUp } from "react-icons/bs";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Drawer, Image, Input, Empty, Skeleton } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoSearchOutline } from "react-icons/io5";
import { VscLoading } from "react-icons/vsc";
import { SlLocationPin } from "react-icons/sl";
import moment from "moment";

const HomeMobileSearch = ({
  router,
  type,
  sports,
  handleSportSelection,
  destinations,
  isDestLoading,
  hasSearchedDest,
  handleDestSearch,
  handleDestSelection,
  isCalendarLoading,
  calendarData,
  selectedMonth,
  setSelectedMonth,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  applyFilters,
}) => {
  // General
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  // Sport - step 1
  const [sportValue, setSportValue] = useState("");
  // Destinations - step 2
  const [destTitle, setDestTitle] = useState("");
  const [hideDestItems, setHideDestItems] = useState(false);
  // Dates - step 3
  const [isFlexibale, setIsFlexibale] = useState(true);

  const swiperRef = useRef(null);

  const { t, i18n } = useTranslation("common");

  // Hide dest options after selection
  useEffect(() => {
    // Show when Dest val is empty
    if (destTitle === "") {
      setHideDestItems(false);
    }
  }, [destTitle]);

  const changeStep = (stp) => {
    setStep(stp);
    swiperRef.current?.slideTo(stp - 1);
  };

  const destSelected = (dest, type) => {
    setDestTitle(dest?.title);
    handleDestSelection(dest, type);
    changeStep(3);
    setHideDestItems(true);
  };

  return (
    <div className="w-full flex justify-center">
      {/* Search bar */}
      {type === "home" && (
        <div
          onClick={() => setIsOpen(true)}
          className={`w-full min-w-full h-20 sm:min-w-[80%] md:w-[60%] bg-white rounded-full shadow-xl flex items-center justify-between cursor-pointer`}
        >
          <div className="flex flex-col gap-0 items-start justify-center px-8 sm:px-10 text-left !text-black w-full">
            <p className="font-bold text-base">
              {step === 1 && t("frontpage.sport_select")}
              {step === 2 && t("frontpage.destination_select")}
              {step === 3 && t("frontpage.travel_date")}
            </p>
            <p className="text-base font-normal">
              {step === 1 && t("frontpage.sport_select_ph")}
              {step === 2 && t("frontpage.destination_select_ph")}
              {step === 3 && t("frontpage.travel_date_ph")}
            </p>
          </div>

          <button className="rounded-full font-medium bg-[#FF9F1C] flex items-center justify-center p-4 sm:p-5 mr-2 shadow-lg">
            <SearchIcon />
          </button>
        </div>
      )}

      {/* Drawer */}
      <Drawer
        open={type === "home" ? isOpen : true}
        onClose={() => setIsOpen(false)}
        placement="right"
        footer={
          <div className="w-full px-2">
            <Button
              className="w-full rounded-3xl h-[44px] !bg-orange !text-white !font-medium !text-base"
              onClick={() => {
                applyFilters();
                setIsOpen(false);
              }}
            >
              {t("frontpage.search_mobile")}
            </Button>
          </div>
        }
        keyboard
        width="100%"
        height="100%"
        headerStyle={{ display: "none" }}
        className="[&_.ant-drawer-body]:!p-0"
        destroyOnClose
      >
        {/* Top moverii header */}
        <Header page={""} router={router} />

        {/* Tabs - steps */}
        <div className="w-full px-6 border-y flex justify-between items-center pt-3 sticky top-0 bg-white !z-50 shadow">
          <div className="flex items-center gap-4 font-medium text-base">
            <p
              className={`pb-3 transition-all duration-100 ${
                step === 1
                  ? "border-b-2 border-orange font-bold text-black"
                  : "text-gray-50"
              } cursor-pointer`}
              onClick={() => changeStep(1)}
            >
              {t("frontpage.sport_select")}
            </p>
            <p
              className={`pb-3 transition-all duration-100 ${
                step === 2
                  ? "border-b-2 border-orange font-bold text-black"
                  : "text-gray-50"
              } cursor-pointer`}
              onClick={() => changeStep(2)}
            >
              {t("frontpage.destination_select")}
            </p>
            <p
              className={`pb-3 transition-all duration-100 ${
                step === 3
                  ? "border-b-2 border-orange font-bold text-black"
                  : "text-gray-50"
              } cursor-pointer`}
              onClick={() => changeStep(3)}
            >
              {t("frontpage.travel_date")}
            </p>
          </div>

          <div className="w-[18px] h-[18px] -mt-2">
            <IoClose
              className="text-lg text-gray-40 hover:text-black transition-all duration-200 cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>

        {/* Content */}
        <div className="z-[49] pb-10">
          <Swiper
            effect={"fade"}
            slidesPerView={1}
            initialSlide={0}
            className="!mt-4"
            onActiveIndexChange={(i) => setStep(i.realIndex + 1)}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {/* Step 1 - sports */}
            <SwiperSlide className="w-full">
              <ul className="w-full px-6 flex flex-col gap-3">
                {sports?.map((sport, i) => {
                  const isSelected = sportValue
                    ? sportValue === sport?.alias
                      ? true
                      : false
                    : false;

                  return (
                    <li
                      className={`w-full py-3 border-2 px-4 rounded-xl flex items-center justify-between cursor-pointer ${
                        isSelected ? "border-orange" : "border-[#12A3BA]"
                      }`}
                      key={i * Number(sport.id)}
                      onClick={() => {
                        handleSportSelection(sport?.alias);
                        setSportValue(sport?.alias);
                        changeStep(2);
                        setDestTitle("");
                        setHideDestItems(false);
                      }}
                    >
                      <p className="text-base font-normal">{sport.title}</p>
                      {isSelected ? (
                        <Image
                          src="/icon/search/orange_checked.png"
                          alt="checked mark"
                          preview={false}
                          width="20px"
                          height="20px"
                        />
                      ) : (
                        <BsArrowUp className="text-xl rotate-90" />
                      )}
                    </li>
                  );
                })}
              </ul>
            </SwiperSlide>

            {/* Step 2 - Destinations */}
            <SwiperSlide className="w-full px-6">
              {/* Search input */}
              <Input
                value={destTitle}
                placeholder={t("frontpage.mobile_search_destination")}
                onChange={(e) => {
                  setDestTitle(e.target.value);
                  handleDestSearch(e.target.value);
                  setHideDestItems(false);
                }}
                showAction={["focus", "click"]}
                className="my-1 py-3 px-4 rounded-xl !border-2 !border-[#12A3BA] flex flex-row search-border"
                suffix={
                  <span>
                    {isDestLoading ? (
                      <VscLoading className="text-2xl text-[#959499] animate-spin" />
                    ) : hideDestItems ? (
                      <IoClose
                        className="text-2xl text-black cursor-pointer"
                        onClick={() => {
                          setHideDestItems(false);
                          setDestTitle("");
                        }}
                      />
                    ) : (
                      <IoSearchOutline className="text-2xl text-[#959499]" />
                    )}
                  </span>
                }
              />

              {/* destination items */}
              <div
                className={`pt-4 ${
                  hideDestItems ? "hidden" : "flex w-full flex-col gap-3"
                }`}
              >
                {isDestLoading ? (
                  // Loading effect
                  _.range(0, 15).map((item) => (
                    <Skeleton.Button
                      key={"dest placeholder" + item}
                      active
                      block
                      className="!h-[30px] !w-full !mb-2"
                    />
                  ))
                ) : destinations?.continents?.length == 0 &&
                  destinations?.countries?.length == 0 &&
                  destinations?.states?.length == 0 ? (
                  // No destination available
                  <div className="w-full h-full flex flex-col items-center justify-center mt-2">
                    <Image
                      src="/icon/search/no-data.png"
                      alt="no data!"
                      preview={false}
                      width={"200px"}
                      height={"200px"}
                      loading="lazy"
                    />
                    <p className="max-w-[90%] text-center text-wrap !text-black font-medium">
                      {t("search.searchbar_no_data_text_dest")}
                    </p>
                  </div>
                ) : (
                  <ul className="w-full flex flex-col gap-2">
                    {/* All destinations */}
                    <li
                      className="text-base cursor-pointer flex items-center gap-x-2"
                      onClick={() => destSelected("0", "country")}
                    >
                      <SlLocationPin className="text-lg" />
                      <p className="font-medium">{t("frontpage.all_des")}</p>
                    </li>

                    {/* continents */}
                    {destinations?.continents?.length > 0 && (
                      <div className="flex flex-col gap-4">
                        <li className="text-base font-bold text-[#959499] mt-4">
                          <p>{t("searchbar_category_continents")}</p>
                        </li>
                        {destinations?.continents?.map((des, i) => (
                          <li
                            key={des?.id + i + Math.random()}
                            className="text-base font-medium cursor-pointer flex items-center gap-x-2"
                            onClick={() => destSelected(des, "continent")}
                          >
                            <SlLocationPin className="text-lg" />
                            <p>{des?.title}</p>
                          </li>
                        ))}
                      </div>
                    )}

                    {/* countries */}
                    {destinations?.countries?.length > 0 && (
                      <div className="flex flex-col gap-4">
                        <li className="text-base font-bold text-[#959499] mt-4">
                          <p>{t("searchbar_category_countries")}</p>
                        </li>
                        {destinations?.countries?.map((des, i) => (
                          <li
                            key={des?.id * i}
                            className="text-base cursor-pointer flex items-center gap-x-2 font-medium"
                            onClick={() => destSelected(des, "country")}
                          >
                            <SlLocationPin className="text-lg" />
                            <p>{des?.title}</p>
                          </li>
                        ))}
                      </div>
                    )}

                    {/* countries */}
                    {hasSearchedDest && destinations?.states?.length > 0 && (
                      <div className="flex flex-col gap-4">
                        <li className="text-base font-bold text-[#959499] mt-4">
                          <p>{t("searchbar_category_states")}</p>
                        </li>
                        {destinations?.states?.map((des, i) => (
                          <li
                            key={des?.id * i}
                            className="text-base cursor-pointer flex items-center gap-x-2 font-medium"
                            onClick={() => destSelected(des, "state")}
                          >
                            <SlLocationPin className="text-lg" />
                            <p>{des?.title}</p>
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                )}
              </div>
            </SwiperSlide>

            {/* Step 3 - Dates */}
            <SwiperSlide className="w-full px-6">
              <div className="mb-0 flex w-full items-center justify-center mt-2">
                <TailwindToggle
                  setValue={setIsFlexibale}
                  value={isFlexibale}
                  isMobile={true}
                />
              </div>

              {/* Dates */}
              <div className="pt-4 w-full">
                {!isCalendarLoading ? (
                  isFlexibale ? (
                    !calendarData?.flexible?.full_access &&
                    calendarData?.flexible?.valid_month.length == 0 ? (
                      // No date available
                      <div className="w-full h-full flex flex-col items-center justify-center mt-2">
                        <Image
                          src="/icon/search/no-data.png"
                          alt="no data!"
                          preview={false}
                          width={"200px"}
                          height={"200px"}
                          loading="lazy"
                        />
                        <p className="max-w-[40%] text-center text-wrap !text-black font-medium">
                          {t("search.searchbar_no_data_text_dates")}
                        </p>
                      </div>
                    ) : (
                      // Flexible - Month selection
                      <div className="w-full sm:max-w-[350px] grid grid-cols-2 mx-auto gap-2">
                        {_.range(1, 13).map((monthNum) => {
                          const month = moment()
                            .add(monthNum, "M")
                            .format("YYYY-MM");

                          // if full_access is false, only allow selecting valid_month
                          const isBlocked =
                            !calendarData?.flexible?.full_access &&
                            calendarData?.flexible?.valid_month?.length > 0 &&
                            !calendarData?.flexible?.valid_month?.some(
                              (mo) => moment(mo).format("YYYY-MM") === month
                            );

                          return (
                            <div
                              key={monthNum}
                              title={moment()
                                .add(monthNum, "M")
                                .format(
                                  i18n.language === "en" ? "YYYY/MM" : "YYYY.MM"
                                )}
                              className={`border border-gray-400 rounded-2xl w-full py-3 shadow-sm flex flex-col items-center justify-center transition-all duration-200 px-2 text-sm ${
                                isBlocked
                                  ? // Block month if not available
                                    "bg-gray-70 !cursor-not-allowed"
                                  : "cursor-pointer bg-white"
                              } ${
                                selectedMonth?.includes(month)
                                  ? "border-[3px] !border-[#FF9F1C]"
                                  : ""
                              }`}
                              onClick={() => {
                                if (!isBlocked) {
                                  // Check if the month was already selected
                                  if (selectedMonth?.includes(month)) {
                                    // Filter out the selected item
                                    const filteredItems = selectedMonth?.filter(
                                      (m) => m !== month
                                    );
                                    setSelectedMonth(filteredItems);
                                  } else {
                                    // Add new selected month
                                    setSelectedMonth([...selectedMonth, month]);
                                  }

                                  // Clear specific date value
                                  setStartDate(null);
                                  setEndDate(null);
                                }
                              }}
                            >
                              <p
                                className={`${
                                  selectedMonth?.includes(month)
                                    ? "font-bold"
                                    : "font-medium"
                                }`}
                              >
                                {moment().add(monthNum, "M").format("MMMM")}
                              </p>
                              <p
                                className={`${
                                  selectedMonth?.includes(month)
                                    ? "font-medium"
                                    : "font-normal"
                                }`}
                              >
                                {moment().add(monthNum, "M").format("YYYY")}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )
                  ) : // Specific date selection - Range picker
                  calendarData?.specific?.full_access_calender == false &&
                    validDates?.length == 0 ? (
                    // No date available
                    <div className="w-full h-full flex flex-col items-center justify-center mt-2">
                      <Image
                        src="/icon/search/no-data.png"
                        alt="no data!"
                        preview={false}
                        width={"200px"}
                        height={"200px"}
                        loading="lazy"
                      />
                      <p className="max-w-[90%] text-center text-wrap !text-black font-medium">
                        {t("search.searchbar_no_data_text_dates")}
                      </p>
                    </div>
                  ) : (
                    <CustomRangePicker
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      startDate={startDate}
                      endDate={endDate}
                      data={calendarData?.specific}
                      setSelectedMonth={setSelectedMonth}
                    />
                  )
                ) : (
                  //  Loading effect
                  <div className="w-full sm:max-w-[350px] grid grid-cols-2 gap-3 mx-auto">
                    {_.range(0, 12).map((item) => (
                      <Skeleton.Button
                        active
                        className="!w-full !h-[80px] !rounded-2xl !shadow-md"
                        key={item}
                      />
                    ))}
                  </div>
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Drawer>
    </div>
  );
};

export default HomeMobileSearch;
