import HomeMobileSearch from "./HomeMobileSearch";
import { SearchIcon } from "@components/search/ComponentIcon";
import SearchCalendar from "./SearchCalendar";
import request from "@libs/request/Api";
import { useState, useEffect, useRef } from "react";
import { Image, Select, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useRouter } from "next/router";

const { Option, OptGroup } = Select;

const Bar = ({
  type,
  applyFiltersInSearch,
  isMobile,
  filters,
  removeFilter,
  isLoaded,
}) => {
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  // Sport related:
  const [sportValue, setSportValue] = useState();
  const [sportPreviewVal, setSportPreviewVal] = useState([]);
  const [showSportFilterPreview, setShowSportFilterPreview] = useState(false);
  const [selectedSportData, setSelectedSportData] = useState(null);
  const [openSport, setOpenSport] = useState(false);
  const [selectedSportId, setSelectedSportId] = useState();

  // Destination related:
  const [destValue, setDestValue] = useState();
  const [destPreviewVal, setDestPreviewVal] = useState([]);
  const [showDestFilterPreview, setShowDestFilterPreview] = useState(false);
  const [selectedDestData, setSelectedDestData] = useState(null);
  const [openDest, setOpenDest] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [isDestLoading, setIsDestLoading] = useState(false);
  const [hasSearchedDest, setHasSearchedDest] = useState(false);
  const [defaultDests, setDefaultDests] = useState([]);
  const [destType, setDestType] = useState("");

  // Date box related:
  const [openDateBox, setOpenDateBox] = useState(false);
  const [datePreviewVal, setDatePreviewVal] = useState([]);
  const [showDateFilterPreview, setShowDateFilterPreview] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const { t, i18n } = useTranslation("common");
  const destRef = useRef(null);
  const router = useRouter();

  const selectClasses =
    "!w-full !text-sm [&_.ant-select-selection-placeholder]:!text-[#a1a0a6] !text-gray-900 [&_.ant-select-selector]:!p-0 [&_.ant-select-selector]:!rounded-none [&_.ant-select-selector]:!border-none [&_.ant-select-selector]:!h-[21px]";
  const selectPopupClasses =
    "md:!h-[435px] [&_.rc-virtual-list]:md:!h-full [&_.rc-virtual-list-holder]:md:!min-h-full [&_.rc-virtual-list-holder]:md:!max-h-[435px] [&_.rc-virtual-list-holder-inner]:md:!h-full py-3 !pb-5 !rounded-3xl [&_.ant-select-item-option]:!text-sm [&_.ant-select-item-option]:!font-medium [&_.ant-select-item-option]:!py-[7px] [&_.ant-select-item-option]:!px-5 !z-[100]";
  const dateFormat = i18n.language === "en" ? "YYYY/MM/DD" : "YYYY.MM.DD";
  const sports = [
    {
      id: "0",
      title: t("frontpage.all_sports"),
      alias: "0",
    },
    {
      id: "3",
      title: t("search.searchbar_sports_option_1"),
      alias: "surf",
    },
    {
      id: "2",
      title: t("search.searchbar_sports_option_2"),
      alias: "yoga",
    },
    {
      id: "5",
      title: t("search.searchbar_sports_option_3"),
      alias: "fitness",
    },
    {
      id: "4",
      title: t("search.searchbar_sports_option_4"),
      alias: "hiking",
    },
    {
      id: "210",
      title: t("search.searchbar_sports_option_5"),
      alias: "klettern-bouldern",
    },
    {
      id: "222",
      title: t("search.searchbar_sports_option_6"),
      alias: "bunter-aktivmix",
    },
    {
      id: "6",
      title: t("search.searchbar_sports_option_7"),
      alias: "martial-arts",
    },
    {
      id: "219",
      title: t("search.searchbar_sports_option_8"),
      alias: "kajak-kanu",
    },
    {
      id: "211",
      title: t("search.searchbar_sports_option_9"),
      alias: "fahrrad-mountainbike",
    },
    {
      id: "231",
      title: t("search.searchbar_sports_option_10"),
      alias: "kitesurfen",
    },
    {
      id: "194",
      title: t("search.searchbar_sports_option_11"),
      alias: "winter-sports",
    },
  ];

  // Handle boxes close/open
  useEffect(() => {
    if (sportValue !== undefined) {
      setOpenSport(false);
      setOpenDest(true);
      !isMobile && !showDestFilterPreview && destRef.current.focus();
    }

    if (destValue !== undefined) {
      setOpenSport(false);
      setOpenDest(false);
      setOpenDateBox(true);
    }
  }, [sportValue, destValue]);

  useEffect(() => {
    getLatestDestinations();
  }, []);

  useEffect(() => {
    if ((openSport || openDest || openDateBox) && type === "home") {
      setIsBoxOpen(true);
    } else {
      setIsBoxOpen(false);
    }
  }, [openSport, openDest, openDateBox]);

  // Update filters value if filters were already selected
  useEffect(() => {
    if (type === "search" && Object.keys(filters)?.length > 0) {
      const sportTemp = [];
      const destTemp = [];
      const dateTemp = [];
      const sportData = [];

      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
          filters[key].forEach((d) => {
            if (key === "sport") {
              sportTemp.push(d?.title || "");
              sportData.push(d);
            }

            if (["continent", "country", "state"].includes(key)) {
              destTemp.push(d?.title || "");
            }

            if (key === "month" && !filters["from"] && !filters["to"]) {
              dateTemp.push(d?.title || "");
            }

            if (key === "from" || key === "to") {
              dateTemp.push(
                moment(d?.title, "DD-MM-YYYY").format(dateFormat) || ""
              );
            }
          });
        }
      });

      // Batch state updates into one
      setSportPreviewVal(sportTemp);
      setDestPreviewVal(destTemp);
      setDatePreviewVal(dateTemp);

      // Set filter preview visibility based on content
      setShowSportFilterPreview(sportTemp.length > 0);
      setShowDestFilterPreview(destTemp.length > 0);
      setShowDateFilterPreview(dateTemp.length > 0);

      // Get related destinations
      if (sportTemp.length > 0) {
        const lastSportAlias = sportData[sportData?.length - 1]?.alias;
        getDestinations(lastSportAlias);
        const findSport = sports.find(
          (sport) => sport.alias === lastSportAlias
        );
        setSelectedSportId(findSport?.id || "0");
        setSportValue(lastSportAlias);
      }
    } else {
      // Reset all previews if no filters
      setShowSportFilterPreview(false);
      setShowDestFilterPreview(false);
      setShowDateFilterPreview(false);
    }
  }, [filters, router.query, isLoaded]);

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedMonth([]);
    setIsDateSelected(false);
  };

  // On form finish - submit
  const applyFilters = () => {
    // close date box
    setOpenDateBox(false);

    // build query params - URL encoding
    const buildQueryParam = (key, value) =>
      `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

    // build date range query params
    const buildDateRangeParam = (start, end) => {
      const formattedStart = moment(start).format("YYYY-MM-DD"); // Formats the start date
      const formattedEnd = moment(end).format("YYYY-MM-DD"); // Formats the end date
      return `from=${formattedStart}&to=${formattedEnd}`; // Returns the date range query string
    };

    // handle month array and convert it to query string
    const buildMonthParam = (months) =>
      months.map((month) => buildQueryParam("month", month)).join("&");

    const queryParams = [];

    // Sport Filter
    if (sportValue && sportValue !== "0") {
      queryParams.push(buildQueryParam("sport", sportValue));
    }

    // destination Filter
    if (destValue && destValue !== "0") {
      // Find the key/destType for the selected country from the countries list
      const countryKey =
        _.get(_.find(destinations, { alias: destValue }), "key", destType) ||
        destType;

      // Handle URL encoding for countries with "&" in their names
      // Replace "&" with "%26" to safely include it in the URL
      const encodedCountry = destValue?.includes("&")
        ? destValue.replace("&", "%26")
        : destValue;

      queryParams.push(buildQueryParam(countryKey, encodedCountry));
    }

    // Date Range Filter - speecific dates
    if (startDate && endDate) {
      queryParams.push(buildDateRangeParam(startDate, endDate));
    }

    // Month Filter - flexibile dates
    if (!startDate && selectedMonth) {
      if (Array.isArray(selectedMonth)) {
        queryParams.push(buildMonthParam(selectedMonth));
      }
    }

    // Filter out any empty params before joining with "&"
    const finalQueryString = queryParams.filter(Boolean).join("&");

    // redirect
    if (finalQueryString) {
      if (type === "search") {
        router.push(`/s/moverii?${finalQueryString}`, undefined, {
          shallow: true,
        });
      } else {
        location.href = `/s/moverii?${finalQueryString}`;
      }

      const filters = [];

      if (type === "search") {
        // Add filters to page
        if (selectedSportData) {
          filters.push({
            key: "sport",
            alias: selectedSportData?.alias,
            title: selectedSportData?.title,
          });
        }

        if (selectedDestData) {
          filters.push({
            key: selectedDestData?.key,
            alias: selectedDestData?.alias,
            title: selectedDestData?.title,
          });
        }

        if (selectedMonth?.length > 0) {
          selectedMonth?.map((m) =>
            filters.push({
              key: "month",
              alias: m,
              title: moment(m, "YYYY-MM").format("YYYY MMMM"),
            })
          );
        }

        if (startDate && endDate) {
          filters.push({
            key: "from",
            alias: moment(startDate).format("DD-MM-YYYY"),
            title: toString(moment(startDate).format(dateFormat)),
          });
          filters.push({
            key: "to",
            alias: moment(endDate).format("DD-MM-YYYY"),
            title: toString(moment(endDate).format(dateFormat)),
          });
        }

        // Make a request to get results based on filters
        applyFiltersInSearch(filters, finalQueryString);
      }
    } else if (type !== "search") {
      router.push(`/s/moverii?page=1`);
    }
  };

  const getLatestDestinations = () => {
    // Get latest destinations - Request on load
    setIsDestLoading(true);
    request
      .get("/search-latest")
      .then((result) => {
        setDestinations(result?.data);
        setDefaultDests(result?.data);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setIsDestLoading(false));
  };

  const getDestinations = (sport) => {
    // Get destinations after selecting sport
    setIsDestLoading(true);
    request
      .get(`/search-data?sport=${sport}`)
      .then((result) => {
        setDestinations(result?.data);
        setDefaultDests(result?.data);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setIsDestLoading(false));
  };

  // Sport has been selected
  const handleSportSelection = (e) => {
    if (!e) return;

    // Handle boxes open/close
    setOpenSport(false);
    setOpenDest(true);
    !isMobile && destRef?.current?.focus();

    // update sport value
    setSportValue(e);
    // send req to get dest
    getDestinations(e);

    // Empty dest value
    setDestValue();

    // Find selected sport ID
    const sportId = sports.find((sport) => sport.alias === e);
    setSelectedSportId(sportId?.id || "0");
    setSelectedSportData(sportId);
  };

  // destination has been selected
  const handleDestSelection = (e, type) => {
    if (!e) return;

    // Clear dates if already selected
    resetDates();
    let destId = "";
    let destination_type = "";

    if (isMobile) {
      setDestValue(e?.alias);
      destId = e?.id || "0";
      setDestType(e?.key || "country");
      setSelectedDestData(e);
    } else {
      setDestValue(e);

      // Find selected destination ID & type
      const temp = [
        ...(destinations?.continents || []),
        ...(destinations?.countries || []),
        ...(destinations?.states || []),
      ];

      const destFiltered = temp.find((dest) => dest.alias === e);
      destId = destFiltered?.id || "0";
      destination_type = destFiltered?.key || "country";
      setDestType(destFiltered?.key || "country");
      setSelectedDestData(destFiltered);
    }

    // Get dates data
    setIsCalendarLoading(true);
    request
      .get(
        `/search-travel-dates?sport_id=${selectedSportId}&destination_id=${destId}&destination_type=${
          isMobile ? type : destination_type || "country"
        }`
      )
      .then((result) => {
        setCalendarData(result?.data);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setIsCalendarLoading(false));
  };

  // type-in destination search
  const handleDestSearch = (e) => {
    setIsDestLoading(true);
    setHasSearchedDest(e === "" ? false : true);

    if (!e || e === "") {
      // Bring back original destinations
      setIsDestLoading(false);
      return setDestinations(defaultDests);
    }

    setDestValue();

    setTimeout(() => {
      const queryParams = sportValue
        ? `?category=${sportValue}&country=${e}`
        : `?country=${e}`;
      request
        .get(`/offer/countries${queryParams}`)
        .then((result) => {
          setDestinations(result?.data);
        })
        .catch((error) => {
          console.log("Error:", error.response);
        })
        .finally(() => setIsDestLoading(false));
    }, 500);
  };

  if (isMobile && type !== "search") {
    // Mobile search - used in Home & header
    return (
      <HomeMobileSearch
        router={router}
        type={type}
        // sport-related
        sports={sports}
        handleSportSelection={(sport) => handleSportSelection(sport)}
        // destination-related
        destinations={destinations}
        isDestLoading={isDestLoading}
        hasSearchedDest={hasSearchedDest}
        handleDestSearch={(dest) => handleDestSearch(dest)}
        handleDestSelection={(dest, type) => handleDestSelection(dest, type)}
        // Calendar-related
        isCalendarLoading={isCalendarLoading}
        calendarData={calendarData}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        // Submit
        applyFilters={applyFilters}
      />
    );
  }

  return (
    <div
      className={`${
        type === "header"
          ? "!mt-[100px]"
          : "w-full flex flex-col items-center justify-center container"
      }`}
    >
      {/* search bar */}
      <div
        className={`w-full lg:w-[912px] h-[75px] rounded-full bg-gray-100 z-50 py-2 px-4 md:pl-8 pr-20 flex items-center relative overflow-hidden ${
          type === "search" &&
          "!border-[3px] !border-green mt-[37.5px] shadow-sm"
        } ${isBoxOpen && type === "home" ? "-mt-[550px]" : ""} ${
          type === "header" ? "!border-[3px] !border-green z-[100]" : ""
        }`}
      >
        {/* cols */}
        <div className="w-full h-full grid grid-cols-3">
          {/* type of sports */}
          <div
            onClick={() => {
              setShowSportFilterPreview(false);
              setOpenDateBox(false);
              setOpenSport((prev) => !prev);
              setOpenDest(false);
            }}
            className={`border-r-2 flex flex-col justify-center gap-[2px] cursor-pointer`}
          >
            <p className="font-medium text-sm">{t("frontpage.sport_select")}</p>
            {showSportFilterPreview && sportPreviewVal?.length > 0 ? (
              <div className="w-full flex items-center justify-between pr-2">
                <p className="!line-clamp-1">
                  {sportPreviewVal?.map(
                    (sport, i) => `${i > 0 ? " - " : ""}${sport}`
                  )}
                </p>

                <div>
                  <IoCloseCircleSharp
                    className="text-gray-70 hover:text-gray-500 text-base"
                    onClick={() => {
                      filters["sport"] && removeFilter("sport");

                      setSportPreviewVal(false);
                      setSportPreviewVal([]);
                      setSportValue();
                      setSelectedSportData(null);
                      setSelectedSportId();
                      getLatestDestinations();
                    }}
                  />
                </div>
              </div>
            ) : (
              <Select
                tabIndex={0}
                open={openSport}
                value={sportValue}
                placeholder={t("frontpage.sport_select_ph")}
                bordered={false}
                className={selectClasses}
                popupClassName={`${selectPopupClasses} [&_.ant-select-item-option-active]:!bg-[#e9ebee]`}
                dropdownStyle={{ height: "100%" }}
                allowClear
                virtual={false}
                variant="borderless"
                autoFocus
                defaultActiveFirstOption
                onChange={handleSportSelection}
                onClear={() => {
                  setSportValue();
                  setOpenSport(true);
                  setOpenDest(false);
                  setOpenDateBox(false);
                  getLatestDestinations();

                  if (type === "search") {
                    setSportPreviewVal(false);
                    setSportPreviewVal([]);
                  }
                }}
              >
                {sports?.map((sport, i) => (
                  <Option key={i * Number(sport.id)} value={sport.alias}>
                    {sport.title}
                  </Option>
                ))}
              </Select>
            )}
          </div>

          {/* Destinations */}
          <div
            onClick={() => {
              setShowDestFilterPreview(false);
              setOpenDateBox(false);
              setOpenSport(false);
              setOpenDest((prev) => !prev);
            }}
            className="border-r-2 flex flex-col justify-center pl-3 gap-[2px] cursor-pointer"
          >
            <p className="font-medium text-sm">
              {t("frontpage.destination_select")}
            </p>

            {showDestFilterPreview && destPreviewVal?.length > 0 ? (
              <div className="w-full flex items-center justify-between pr-2">
                <p className="!line-clamp-1">
                  {destPreviewVal?.map(
                    (dest, i) => `${i > 0 ? " - " : ""}${dest}`
                  )}
                </p>
                <div>
                  <IoCloseCircleSharp
                    className="text-gray-70 hover:text-gray-500 text-base"
                    onClick={() => {
                      removeFilter("continent", _);
                      removeFilter("country", _);
                      removeFilter("state");

                      setDestPreviewVal([]);
                      setShowDestFilterPreview(false);
                      setDestValue();
                      setDestType("");
                      setHasSearchedDest(false);
                      setSelectedDestData(null);
                    }}
                  />
                </div>
              </div>
            ) : (
              <Select
                tabIndex={1}
                open={openDest}
                value={destValue}
                ref={destRef}
                placeholder={t("frontpage.destination_select_ph")}
                bordered={false}
                allowClear
                showSearch
                onChange={handleDestSelection}
                onSearch={handleDestSearch}
                virtual={false}
                defaultActiveFirstOption
                variant="borderless"
                className={`${selectClasses} [&_.ant-select-selection-search-input]:!pl-0 [&_.ant-select-selection-search]:!-ml-[10px] [&_.ant-select-selection-search]:!-mt-[5px]`}
                popupClassName={`${selectPopupClasses} [&_.ant-select-item-group]:!text-sm [&_.ant-select-item-group]:!px-5 [&_.ant-select-item-group]:!pb-[7px] [&_.ant-select-item-group]:!pt-[10px] [&_.ant-select-item-group]:!font-medium [&_.ant-select-item-group]:!text-[#99989c] [&_.rc-virtual-list-holder-inner]:last:!pb-8 [&_.ant-select-item-option-active]:!bg-${
                  isDestLoading ||
                  (destinations?.continents?.length == 0 &&
                    destinations?.countries?.length == 0 &&
                    destinations?.states?.length == 0)
                    ? "white"
                    : "[#e9ebee]"
                }`}
                onClear={() => {
                  setDestValue();
                  setOpenSport(false);
                  setOpenDest(true);
                  setOpenDateBox(false);
                  destRef.current.focus();

                  if (type === "search") {
                    setShowDestFilterPreview(false);
                    setSportPreviewVal([]);
                  }
                }}
                notFoundContent={
                  isDestLoading ? (
                    <div className="w-full h-full">
                      <div className="w-full h-[380px] flex flex-col gap-5">
                        {_.range(0, 10).map((item) => (
                          <Skeleton.Button
                            active
                            key={"destination placeholder" + item}
                            block
                            className="!h-[30px] !w-full"
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    // No destination available
                    <div className="w-full h-full flex flex-col items-center justify-center mt-5">
                      <Image
                        src="/icon/search/no-data.png"
                        alt="no data!"
                        preview={false}
                        width={"200px"}
                        height={"200px"}
                        loading="lazy"
                      />
                      <p className="max-w-full text-center text-wrap !text-black">
                        {t("search.searchbar_no_data_text_dest")}
                      </p>
                    </div>
                  )
                }
              >
                {(destinations?.continents?.length > 0 ||
                  destinations?.countries?.length > 0 ||
                  destinations?.states?.length > 0) && (
                  <Option value="0">{t("frontpage.all_des")}</Option>
                )}
                {isDestLoading ? (
                  // Loading effect
                  <div className="w-full h-full">
                    <div className="w-full h-[380px] flex flex-col gap-5">
                      {_.range(0, 10).map((item) => (
                        <Skeleton.Button
                          active
                          key={"destination placeholder" + item}
                          block
                          className="!h-[30px] !w-full"
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    {destinations?.continents?.length == 0 &&
                    destinations?.countries?.length == 0 &&
                    destinations?.states?.length == 0 ? (
                      // No destination available
                      <div className="w-full h-full flex flex-col items-center justify-center mt-5">
                        <Image
                          src="/icon/search/no-data.png"
                          alt="no data!"
                          preview={false}
                          width={"200px"}
                          height={"200px"}
                          loading="lazy"
                        />
                        <p className="max-w-full text-center text-wrap">
                          {t("search.searchbar_no_data_text_dest")}
                        </p>
                      </div>
                    ) : (
                      <>
                        {/* continents */}
                        {destinations?.continents?.length > 0 && (
                          <OptGroup label={t("searchbar_category_continents")}>
                            {destinations?.continents?.map((des, i) => (
                              <Option
                                key={des?.id + i + Math.random()}
                                value={des?.alias}
                              >
                                {des?.title}
                              </Option>
                            ))}
                          </OptGroup>
                        )}

                        {/* countries */}
                        {destinations?.countries?.length > 0 && (
                          <OptGroup label={t("searchbar_category_countries")}>
                            {destinations?.countries?.map((des, i) => (
                              <Option key={des?.id * i} value={des?.alias}>
                                {des?.title}
                              </Option>
                            ))}
                          </OptGroup>
                        )}

                        {/* Display only after type-in search - States */}
                        {hasSearchedDest &&
                          destinations?.states?.length > 0 && (
                            <OptGroup label={t("searchbar_category_states")}>
                              {destinations?.states?.map((des, i) => (
                                <Option key={des?.id * i} value={des?.alias}>
                                  {des?.title}
                                </Option>
                              ))}
                            </OptGroup>
                          )}
                      </>
                    )}
                  </>
                )}
              </Select>
            )}
          </div>

          {/* Date */}
          <div
            tabIndex={2}
            onClick={() => {
              setShowDateFilterPreview(false);
              setOpenDateBox((prev) => !prev);
              setOpenSport(false);
              setOpenDest(false);
            }}
            className="pl-3 flex flex-col justify-center gap-[2px] cursor-pointer"
          >
            <p className="font-medium text-sm">{t("frontpage.travel_date")}</p>
            {showDateFilterPreview && datePreviewVal?.length > 0 ? (
              <div className="w-full flex items-center justify-between">
                <p className="!line-clamp-1">
                  {datePreviewVal?.map(
                    (date, i) => `${i > 0 ? " - " : ""}${date}`
                  )}
                </p>

                <div>
                  <IoCloseCircleSharp
                    className="text-gray-70 hover:text-gray-500 text-base"
                    onClick={() => {
                      filters["month"] &&
                        removeFilter(
                          "month",
                          filters["to"] || filters["from"] ? _ : null
                        );
                      if (filters["to"] || filters["from"]) {
                        removeFilter("from", _);
                        removeFilter("to");
                      }

                      setDatePreviewVal([]);
                      setShowDateFilterPreview(false);
                      setOpenDateBox(true);
                      setSelectedMonth([]);
                      setStartDate(null);
                      setEndDate(null);
                      setIsDateSelected(false);
                    }}
                  />
                </div>
              </div>
            ) : isDateSelected ? (
              <div className="w-full flex items-center justify-between">
                <p className="!text-sm font-normal text-[#2B2D42] !line-clamp-1">
                  {startDate &&
                    endDate &&
                    `${moment(startDate).format(dateFormat)} - ${moment(
                      endDate
                    ).format(dateFormat)}`}

                  {selectedMonth &&
                    selectedMonth?.map(
                      (month, i) =>
                        `${i >= 1 ? " - " : ""} ${moment(month)
                          .locale(i18n.language)
                          .format("MMMM YYYY")}`
                    )}
                </p>

                <div>
                  <IoCloseCircleSharp
                    className="text-gray-70 hover:text-gray-500 text-base"
                    onClick={() => {
                      resetDates();
                      setOpenDateBox(true);
                    }}
                  />
                </div>
              </div>
            ) : (
              <p className="text-[#a1a0a6] text-sm">
                {t("frontpage.travel_date_ph")}
              </p>
            )}
          </div>
        </div>

        {/* search button */}
        <button
          className={`bg-[#FF9F1C] rounded-full w-14 h-14 flex flex-col items-center justify-center absolute right-2 transition-all duration-700`}
          onClick={(e) => {
            e.stopPropagation();
            applyFilters();
            setOpenSport(false);
            setOpenDest(false);
            setOpenDateBox(false);
          }}
        >
          <SearchIcon />
        </button>
      </div>

      {/* Date box */}
      {openDateBox && (
        <div
          onClick={(e) => e.stopPropagation()}
          className={`w-full container lg:w-[912px] min-h-[400px] rounded-3xl bg-white !z-50 absolute shadow ${
            type === "home"
              ? `pt-2 ${isBoxOpen ? "top-[180px]" : ""}`
              : type === "header"
              ? "top-[180px]"
              : "top-[82%]"
          }`}
        >
          <SearchCalendar
            isLoading={isCalendarLoading}
            calendarData={calendarData}
            setSelectedMonth={setSelectedMonth}
            selectedMonth={selectedMonth}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            endDate={endDate}
            startDate={startDate}
            setOpenDateBox={setOpenDateBox}
            setIsDateSelected={setIsDateSelected}
          />
        </div>
      )}

      {/* Glass shadow effect - Handle close boxes */}
      {(openSport || openDest || openDateBox) && type !== "header" && (
        <span
          className={`!absolute top-0 bottom-0 right-0 left-0 w-[99.2vw] glass_shadow z-[49] ${
            type === "home" ? "h-[700vh]" : "h-[900vh]"
          }`}
          onClick={() => {
            setOpenSport(false);
            setOpenDest(false);
            setOpenDateBox(false);
          }}
        />
      )}
    </div>
  );
};

export default Bar;
