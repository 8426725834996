import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

const CustomRangePicker = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  data,
  setSelectedMonth,
}) => {
  const [loadMoreMonths, setLoadMoreMonths] = useState(false);
  const [months, setMonths] = useState([]);
  const loadMoreRef = useRef(null);

  const { t, i18n } = useTranslation("common");
  // specific calendar data
  const isFullAccess = data?.full_access_calender;
  const workDays = data?.work_dates || [];
  const excludeDates = data?.exclude_dates || [];
  const validDates = data?.valid_dates || [];

  useEffect(() => {
    // Add more months when reached the end
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the element is in view
        setLoadMoreMonths(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // Observe the component using the ref
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    // Cleanup
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Add 5 month on first load
    const monthsTemp = [];
    for (let i = 0; i < 5; i++) {
      monthsTemp.push(moment().clone().add(i, "months"));
    }

    return setMonths(monthsTemp);
  }, []);

  useEffect(() => {
    // Add 5 more months
    if (loadMoreMonths) {
      const monthsTemp = [];
      const lastMonth = months[months.length - 1];

      for (let i = 1; i <= 5; i++) {
        // Add 5 more months, starting from the next month after the last one
        monthsTemp.push(moment(lastMonth).clone().add(i, "months"));
      }

      // update months
      setMonths([...months, ...monthsTemp]);
    }
  }, [loadMoreMonths]);

  // create an array with days of the month
  const getDaysInMonth = (month) => {
    const startOfMonth = moment(month).startOf("month");
    const endOfMonth = moment(month).endOf("month");
    let day = startOfMonth;
    const days = [];

    while (day <= endOfMonth) {
      days.push(day.clone());
      day = day.add(1, "day");
    }
    return days;
  };

  const handleDateClick = (day, isDisabled) => {
    // Don't select date if disabled
    if (isDisabled) return;
    setSelectedMonth([]);

    // If no start date is selected, set the start date
    if (!startDate) {
      setStartDate(day.format("YYYY-MM-DD"));
    } else if (!endDate && day.isAfter(startDate)) {
      // If start date is selected but no end date, set end date if it comes after start
      setEndDate(day.format("YYYY-MM-DD"));
    } else {
      // Reset the selection
      setStartDate(day.format("YYYY-MM-DD"));
      setEndDate(null);
    }
  };

  const isDisabled = (current) => {
    if (!current) return;

    // Disable past dates - before today
    if (current.isBefore(moment().endOf("day"))) return true;

    // Disable all days except workdays (allow only workdays)
    if (workDays?.length > 0) {
      return !workDays.some((date) => date - 1 == moment(current).format("d"));
    }

    // Full access calendar logic
    if (isFullAccess && excludeDates) {
      // Disable excluded dates
      return excludeDates?.includes(moment(current).format("YYYY-MM-DD"));
    }

    // Restricted calendar logic: only allow validDates
    if (validDates?.length > 0) {
      return !validDates?.includes(moment(current).format("YYYY-MM-DD"));
    }

    // Allow all dates if no restrictions
    return false;
  };

  const isInRange = (day) => {
    // remove first & last day
    const start = moment(startDate)?.add(1, "day");
    const end = moment(endDate)?.subtract(1, "day");

    if (!startDate || !endDate) return false;

    return day.isBetween(start, end, "day", "[]");
  };

  const renderCalendar = (month) => {
    const daysInMonth = getDaysInMonth(month);
    // Day of the week the month starts on
    const startOfMonth = moment(month).startOf("month").day();
    // Empty days for padding
    const paddingDays = Array(startOfMonth).fill(null);

    return (
      <div key={month.format("MM-YYYY")} className="mb-6">
        {/* Month label */}
        <p className="text-center font-bold mb-4">
          {month.locale(i18n.language).format("MMMM YYYY")}
        </p>
        {/* Day of week */}
        <div className="grid grid-cols-7 text-center">
          {_.range(1, 8).map((day) => (
            <p key={day} className="text-sm font-semibold">
              {t(`frontpage.search_bar_days_of_week_${day}`)}
            </p>
          ))}
        </div>
        {/* days */}
        <div className="grid grid-cols-7 gap-1">
          {paddingDays.map((_, i) => (
            <div key={i} />
          ))}
          {daysInMonth.map((day) => (
            <button
              key={day.format("DD-MM-YYYY")}
              className={`p-2 rounded-full text-center ${
                isDisabled(day) ? "" : "hover:bg-orange hover:text-white"
              } ${
                isInRange(day) && !isDisabled(day)
                  ? "bg-[#fff1dd] text-black"
                  : ""
              } ${
                day.isSame(startDate, "day") && !isDisabled(day)
                  ? "bg-orange text-white font-medium"
                  : ""
              } ${
                day.isSame(endDate, "day") && !isDisabled(day)
                  ? "bg-orange text-white font-medium"
                  : ""
              } ${
                isDisabled(day) ? "line-through bg-gray-10 text-gray-40" : ""
              }`}
              onClick={() => handleDateClick(day, isDisabled(day))}
              title={day.format(
                i18n.language === "en" ? "YYYY/MM/DD" : "YYYY.MM.DD"
              )}
            >
              {day.date()}
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="py-4 sm:px-4 w-full sm:max-w-[350px] mx-auto">
      <div className="grid grid-cols-1 gap-6">
        {months?.map((month) => renderCalendar(month))}

        {/* Load more months when reached here */}
        <div ref={loadMoreRef} className="w-full h-5 bg-white" />
      </div>
    </div>
  );
};

export default CustomRangePicker;
