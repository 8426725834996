import { useState, useEffect } from "react";

import Bar from "./Bar";
import SearchpageMobileBar from "./SearchpageMobileBar";

// type = home | header | search
const SearchBarContainer = ({
  type,
  applyFiltersInSearch,
  totalJourneys,
  setOpenMap,
  setOpenFilters,
  filters,
  filtersData,
  isOpen,
  setIsOpen,
  setMobileTitle,
  selectedFilters,
  removeFilter,
  isLoaded,
}) => {
  // For Mobile view - Gets window size
  const size = useWindowSize();
  let isMobile = size.width < 1024 ? true : false;

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  return (
    <>
      {type === "home" && <Bar type={type} isMobile={isMobile} />}

      {type === "header" && isOpen && (
        <div className="flex min-h-screen w-full flex-col items-center bottom-0 top-0 left-0 right-0 justify-start lg:px-0 px-10 absolute overflow-hidden">
          <span
            style={{ zIndex: "90" }}
            className={`absolute top-0 bottom-0 right-0 left-0 w-[100vw] glass_shadow z-[90]`}
            onClick={() => setIsOpen(false)}
          />
          <div
            style={{ zIndex: "100" }}
            className="z-[100] flex w-full flex-col items-center justify-start lg:px-0 px-1"
          >
            <Bar type={type} isMobile={isMobile} />
          </div>
        </div>
      )}

      {type === "search" && (
        <div className="w-full relative md:min-h-[150px]">
          <span className="w-screen bg-[#fad49d] h-[75px] absolute top-0 right-0 left-0" />

          {isMobile ? (
            <SearchpageMobileBar
              totalJourneys={totalJourneys}
              setOpenMap={setOpenMap}
              setOpenFilters={setOpenFilters}
              filters={filters}
              filtersData={filtersData}
              setMobileTitle={setMobileTitle}
              selectedFilters={selectedFilters}
            />
          ) : (
            <Bar
              type={type}
              applyFiltersInSearch={applyFiltersInSearch}
              isMobile={isMobile}
              filters={filters}
              removeFilter={removeFilter}
              isLoaded={isLoaded}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SearchBarContainer;
