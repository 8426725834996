import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoMapOutline } from "react-icons/io5";
import { RxMixerHorizontal } from "react-icons/rx";
import { Skeleton } from "antd";
import moment from "moment";

const SearchpageMobileBar = ({
  totalJourneys,
  setOpenMap,
  setOpenFilters,
  filters,
  filtersData,
  setMobileTitle,
  selectedFilters,
}) => {
  const [sport, setSport] = useState(null);
  const [destination, setDestination] = useState(null);
  const [timePeriod, setTimePeriod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  useEffect(() => {
    setIsLoading(true);
    if (filters["sport"] != undefined && filters["sport"]?.length > 0) {
      const sportsMapping = {
        surf: { en: "Surfcamps", de: "Surfcamps" },
        yoga: { en: "Yoga Retreats", de: "Yoga Retreats" },
        fitness: { en: "Fitness holidays", de: "Fitnessreisen" },
        hiking: { en: "Hiking holidays", de: "Wanderreisen" },
        "klettern-bouldern": {
          en: "Climbing & bouldering holidays",
          de: "Kletter- & Boulderreisen",
        },
        "bunter-aktivmix": { en: "Active mix holidays", de: "Aktivmix-Reisen" },
        "martial-arts": { en: "Martial arts holidays", de: "Kampfsport-Camps" },
        "kajak-kanu": {
          en: "Kayak & canoe holidays",
          de: "Kajak- & Kanu-Reisen",
        },
        "fahrrad-mountainbike": {
          en: "Cycling & MTB holidays",
          de: "Fahrrad- & MTB-Reisen",
        },
        kitesurfen: { en: "Kitesurfing", de: "Kitesurfen" },
        "winter-sports": {
          en: "Winter sports holidays",
          de: "Wintersportreisen",
        },
      };

      const sportTitle =
        sportsMapping[sport]?.[lang] || filters["sport"][0]?.title;
      setSport(sportTitle);
    } else {
      setSport(null);
    }

    // Handle destination filter
    if (filters["continent"] && filters["continent"].length > 0) {
      setDestination(filters["continent"][0]?.title);
    } else if (filters["country"] && filters["country"].length > 0) {
      setDestination(filters["country"][0]?.title);
    } else if (filters["state"] && filters["state"].length > 0) {
      setDestination(filters["state"][0]?.title);
    } else {
      setDestination(null);
    }

    // Handle time period
    if (filters["month"] && filters["month"].length > 0) {
      setTimePeriod(filters["month"][0]?.title);
    } else if (
      filters["from"] &&
      filters["to"] &&
      filters["from"].length > 0 &&
      filters["to"].length > 0
    ) {
      const start = moment(filters["from"][0]?.alias, "DD-MM-YYYY").format(
        lang === "en" ? "DD/MM/YYYY" : "DD.MM.YYYY"
      );
      const end = moment(filters["to"][0]?.alias, "DD-MM-YYYY").format(
        lang === "en" ? "DD/MM/YYYY" : "DD.MM.YYYY"
      );
      setTimePeriod(`${start} - ${end}`);
    } else {
      setTimePeriod(null);
    }

    setIsLoading(false);
    setMobileTitle(handleTitle());
  }, [filters, lang, filtersData]);

  const getDestinationConnector = (destination, lang = "en") => {
    const connectorMapping = {
      // Special rules for destinations
      "Kanarische Inseln": { en: "on the", de: "auf den" },
      Schweiz: { en: "in", de: "in der" },
      Niederlande: { en: "in", de: "in der" },
      Slowakei: { en: "in", de: "in der" },
      Bali: { en: "on", de: "auf" },
      "Sri Lanka": { en: "on", de: "auf" },
      Madagaskar: { en: "on", de: "auf" },

      // Regions
      Balearen: { en: "on the", de: "auf den" },
      "Ionischen Inseln": { en: "on the", de: "auf den" },
      "Ägäischen Inseln": { en: "on the", de: "auf den" },
      Kykladen: { en: "on the", de: "auf den" },
      Lofoten: { en: "on the", de: "auf den" },
      "Galapagos Inseln": { en: "on the", de: "auf den" },
      "Südhalbinsel Bukit": { en: "on the", de: "auf der" },
      Fuerteventura: { en: "on", de: "auf" },
      Teneriffa: { en: "on", de: "auf" },
      Lanzarote: { en: "on", de: "auf" },
      "Gran Canaria": { en: "on", de: "auf" },
      Mallorca: { en: "on", de: "auf" },
      Ibiza: { en: "on", de: "auf" },
      Kreta: { en: "on", de: "auf" },
      Sylt: { en: "on", de: "auf" },
      Rügen: { en: "on", de: "auf" },
      Sardinien: { en: "on", de: "auf" },
      Sizilien: { en: "on", de: "auf" },
      Hvar: { en: "on", de: "auf" },
      Brač: { en: "on", de: "auf" },
      "Nusa Lembongan": { en: "on", de: "auf" },
      "Koh Phangan": { en: "on", de: "auf" },
      "Koh Samui": { en: "on", de: "auf" },
      "Koh Tao": { en: "on", de: "auf" },
      Phuket: { en: "on", de: "auf" },
      "Kaoh Lak": { en: "on", de: "auf" },

      // Coasts and seas
      Nordsee: { en: "by the", de: "an der" },
      Ostsee: { en: "by the", de: "an der" },
      Mosel: { en: "at", de: "an der" },
      "Côte d’Azur": { en: "in the", de: "an der" },
      Adriaküste: { en: "in the", de: "an der" },
      Südküste: { en: "in the", de: "an der" },
      Ostküste: { en: "in the", de: "an der" },
      "Zentrale Südküste": { en: "in the", de: "an der" },
      Pazifikküste: { en: "in the", de: "an der" },

      // Mountains and regions
      Alpen: { en: "in", de: "in den" },
      "Französische Alpen": { en: "in the", de: "in den" },
      "Italienischen Alpen": { en: "in the", de: "in den" },
      Dolomiten: { en: "in the", de: "in den" },
      Voralpen: { en: "in the", de: "in den" },
      "Steiner Alpen": { en: "in the", de: "in den" },
      "Julische Alpen": { en: "in the", de: "in den" },

      // Other regions
      Allgäu: { en: "in the", de: "im" },
      Westerwald: { en: "in the", de: "im" },
      Schwarzwald: { en: "in the", de: "im" },
      Pitztal: { en: "in the", de: "im" },
      Mühlviertel: { en: "in the", de: "im" },
      Zillertal: { en: "in the", de: "im" },
      Värmland: { en: "in", de: "im" },
      Tessin: { en: "in", de: "im" },
      "Berner Oberland": { en: "in the", de: "im" },
      "Triglav Nationalpark": { en: "in the", de: "im" },

      // Other areas
      Eifel: { en: "in the", de: "in der" },
      Toskana: { en: "in", de: "in der" },
      Steiermark: { en: "in", de: "in der" },
      Zentralschweiz: { en: "in", de: "in der" },
      Ostschweiz: { en: "in", de: "in der" },
      Westschweiz: { en: "in", de: "in der" },
      "Hohe Tantra": { en: "in the", de: "in der" },
      "Sahara Wüste": { en: "in the", de: "in der" },

      // Lakes and landmarks
      Chiemsee: { en: "at the", de: "am" },
      Bodensee: { en: "at the", de: "am" },
      Achensee: { en: "at the", de: "am" },
      Kilimandscharo: { en: "at", de: "am" },

      // General default case
      default: { en: "in", de: "in" },
    };

    return (
      connectorMapping[destination]?.[lang] || connectorMapping["default"][lang]
    );
  };

  const handleTitle = () => {
    const connector = getDestinationConnector(destination, lang);

    if (sport && destination && timePeriod) {
      // Case a: All selected (sport, destination, and time period)
      return `${sport} ${connector} ${destination}`;
    } else if (sport && destination && !timePeriod) {
      // Case b: Sport and destination selected, but no time period
      return `${sport} ${connector} ${destination}`;
    } else if (sport && !destination && timePeriod) {
      // Case c: Only sport and time period selected
      return `${sport}`;
    } else if (!sport && destination && timePeriod) {
      // Case c: Only destination and time period selected
      return `${destination}`;
    } else if (sport && !destination && !timePeriod) {
      // Case d: Only sport selected
      return `${sport}`;
    } else if (!sport && destination && !timePeriod) {
      // Case d: Only destination selected
      return `${destination}`;
    } else {
      // Case e: Nothing selected
      return t("search_title");
    }
  };

  const handleSubtitle = () => {
    if (sport && destination && timePeriod) {
      // Case a: All selected
      return `${timePeriod}`;
    } else if (sport && destination && !timePeriod) {
      // Case b: Add time period
      return lang === "en" ? `Add time period` : `Zeitraum hinzufügen`;
    } else if (sport && !destination && timePeriod) {
      // Case c: Time period displayed with sport
      return `${timePeriod}`;
    } else if (!sport && destination && timePeriod) {
      // Case c: Time period displayed with destination
      return `${timePeriod}`;
    } else if ((sport || destination) && !timePeriod) {
      // Case d: Add sport/destination and time period
      return lang === "en"
        ? `Add ${!sport ? "type of sport" : "destination"} and time period`
        : `${!sport ? "Sportart" : "Reiseziel"} und Zeitraum hinzufügen`;
    } else {
      // Case e: Default selection prompt
      return lang === "en"
        ? `Select type of sport, destination and time period`
        : `Sportart, Reiseziel und Zeitraum auswählen`;
    }
  };

  return (
    <div className="w-full mx-auto container px-4 md:px-0 relative pt-[30px] z-[100]">
      <div>
        {/* Blue-bordered banner */}
        <div
          onClick={() => setOpenFilters(true)}
          className="border-[3px] !border-green min-h-[100px] bg-white rounded-3xl p-2 px-4 flex flex-col justify-center items-start cursor-pointer"
        >
          {isLoading ? (
            <Skeleton
              active
              paragraph={{ rows: 1 }}
              className="-mt-4 !w-full"
            />
          ) : (
            <>
              <h1 className="text-lg font-bold">{handleTitle()}</h1>
              <h2 className="text-base font-normal mt-1">{handleSubtitle()}</h2>
            </>
          )}
        </div>

        {/* filters */}
        <div className="mt-5 w-full flex items-center justify-between gap-5 flex-wrap">
          <p className="text-base font-medium">
            {totalJourneys}{" "}
            {totalJourneys <= 1 ? t("search.result") : t("search.results")}
          </p>

          <div className="flex items-center gap-2">
            {/* map */}
            <div
              className="px-4 py-3 bg-[#e3f9fc] rounded-full flex items-center gap-2 cursor-pointer"
              onClick={() => setOpenMap(true)}
            >
              <IoMapOutline className="text-xl" />
              <p className="text-base font-medium">
                {t("wishlist.map_mobile")}
              </p>
            </div>

            {/* Filters */}
            <div
              className={`px-4 py-3 bg-[#e3f9fc] rounded-full flex items-center gap-2 cursor-pointer relative ${
                selectedFilters > 1 ? "!border !border-green" : ""
              }`}
              onClick={() => setOpenFilters(true)}
            >
              {selectedFilters > 1 && (
                <span className="absolute px-1 rounded-full bg-green text-white text-[10px] -top-1 right-0">
                  {selectedFilters}
                </span>
              )}
              <RxMixerHorizontal className="text-[22px]" />
              <p className="text-base font-medium">Filter</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchpageMobileBar;
